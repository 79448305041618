import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  'en': {
    about: 'About the Project',
    aboutUs1: 'Agroxy develops and runs ICT solutions to make agricultural supply chains more efficient.In this project, we are making our contribution to reducing hunger and carbon emission is the agricultural supply chain, by mapping out elements of agricultural storage infrastructure, using a combination of AI tools. Our project helps buyers to locate available commodities closer to them, reducing transportation costs and carbon emissions, while sellers gain wider market access.',
    aboutUs2: 'We also would like to encourage kind donors to use locally available food supplies to reduce hunger, particularly in Africa. This will help the local agricultural production and reduce carbon emission avoiding unnecessarily long-leg transportation.',
    aboutUs3: 'We invite owners of storage facilities to add more information about their facilities and owners of products stored at those facilities make it more accessible for buyers.',
    aboutUs4: 'We also would like to build trust in agricultural trade so all efforts will be made to verify the information supplied. We can’t guarantee the accuracy of information at this stage, so reasonable precautions need to be used when using this site. With time, the quality of the information will improve and trust level will increase.',
    aboutUs5: 'Please let us know if you spot any errors or mistakes.',
    aboutUs6: 'Finally, we would like to thank Alex Radchenko, a Computer Science student from St Andrew’s university for his gratuitous contribution in developing AI solution for locating of the items of agricultural supply chain infrastructure.',
    feeding: 'Feeding the world',
    goal1: 'End poverty in all its forms everywhere.',
    goal2: 'End hunger, achieve food security and improved nutrition and promote sustainable agriculture.',
    goal3: 'Ensure healthy lives and promote well-being for all at all ages.',
    goal4: 'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
    goal5: 'Achieve gender equality and empower all women and girls.',
    goal6: 'Ensure availability and sustainable management of water and sanitation for all.',
    goal7: 'Ensure access to affordable, reliable, sustainable and modern energy for all.',
    goal8: 'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.',
    goal9: 'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.',
    goal10: 'Reduce inequality within and among countries.',
    goal11: 'Make cities and human settlements inclusive, safe, resilient and sustainable.',
    goal12: 'Ensure sustainable consumption and production patterns.',
    goal13: 'Take urgent action to combat climate change and its impacts.',
    goal14: 'Conserve and sustainably use the oceans, seas and marine resources for sustainable development.',
    goal15: 'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.',
    goal16: 'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.',
    goal17: 'Strengthen the means of implementation and revitalize the global partnership for sustainable development.',
    reducing: 'Reducing carbon emissions',
  },
  'ua': {
    about: '',
    aboutUs1: '',
    aboutUs2: '',
    aboutUs3: '',
    aboutUs4: '',
    aboutUs5: '',
    aboutUs6: '',
    feeding: '',
    reducing: '',
  },
  'ru': {
    about: '',
    aboutUs1: '',
    aboutUs2: '',
    aboutUs3: '',
    aboutUs4: '',
    aboutUs5: '',
    aboutUs6: '',
    feeding: '',
    reducing: '',
  },
  'sw': {

    about: 'Kuhusu mradi huo ',
    aboutUs1: 'Agroxy inakua na kuendesha suluhisho za ICT ili kufanya minyororo ya usambazaji wa kilimo iwe na ufanisi zaidi. Katika mradi huu, tunatoa mchango wetu katika kupunguza njaa na chafu ya kaboni ni ugavi wa kilimo, kwa kuchora vitu vya miundombinu ya uhifadhi wa kilimo, kwa kutumia mchanganyiko wa zana za AI . Mradi wetu husaidia wanunuzi kupata bidhaa zinazopatikana karibu nao, kupunguza gharama za usafirishaji na uzalishaji wa kaboni, wakati wauzaji wanapata soko pana. ',
    aboutUs2: 'Tunapenda pia kuhamasisha wafadhili wema kutumia chakula kinachopatikana nchini kupunguza njaa, haswa barani Afrika. Hii itasaidia uzalishaji wa kilimo wa ndani na kupunguza chafu ya kaboni kuzuia usafirishaji wa miguu mirefu bila lazima. ',
    aboutUs3: 'Tunakaribisha wamiliki wa vituo vya kuhifadhia kuongeza habari zaidi juu ya vituo vyao na wamiliki wa bidhaa zilizohifadhiwa kwenye vituo hivyo hufanya iweze kupatikana kwa wanunuzi. ',
    aboutUs4: 'Tunapenda pia kujenga imani katika biashara ya kilimo ili juhudi zote zitafanywa ili kudhibitisha habari iliyotolewa. Hatuwezi kuhakikisha usahihi wa habari katika hatua hii, kwa hivyo tahadhari zinazofaa zinapaswa kutumiwa wakati wa kutumia wavuti hii. Kwa wakati, ubora wa habari utaboresha na kiwango cha uaminifu kitaongezeka.',
    aboutUs5: 'Tafadhali tujulishe ikiwa unaona makosa yoyote au makosa.',
    aboutUs6: 'Mwishowe, tunapenda kumshukuru Alex Radchenko, mwanafunzi wa Sayansi ya Kompyuta kutoka chuo kikuu cha St Andrew kwa mchango wake wa bure katika kutengeneza suluhisho la AI kwa kupata vitu vya miundombinu ya ugavi wa kilimo.',
    feeding: 'Kulisha ulimwengu ',
    reducing: 'Kupunguza uzalishaji wa kaboni ',
  },
  'fr': {
    about: 'À propos du projet ',
    aboutUs1: 'Agroxy développe et exploite des solutions TIC pour rendre les chaînes d\'approvisionnement agricoles plus efficaces. Dans ce projet, nous contribuons à réduire la faim et les émissions de carbone dans la chaîne d\'approvisionnement agricole, en cartographiant les éléments de l\'infrastructure de stockage agricole, en utilisant une combinaison d\'outils d\'IA . ',
    aboutUs2: 'Nous voudrions également encourager les généreux donateurs à utiliser les approvisionnements alimentaires disponibles localement pour réduire la faim, en particulier en Afrique. Cela aidera la production agricole locale et réduira les émissions de carbone en évitant les transports ',
    aboutUs3: 'Nous invitons les propriétaires d\'installations de stockage à ajouter plus d\'informations sur leurs installations et les propriétaires de produits stockés dans ces installations les rendent plus accessibles pour les acheteurs.',
    aboutUs4: 'Nous souhaitons également renforcer la confiance dans le commerce agricole afin que tous les efforts soient faits pour vérifier les informations fournies. Nous ne pouvons garantir l\'exactitude des informations à ce stade, des précautions raisonnables doivent donc être utilisées lors de l\'utilisation de ce site. Avec le temps, la qualité de l\'information s\'améliorera et le niveau de confiance augmentera.',
    aboutUs5: 'S\'il vous plaît laissez-nous savoir si vous repérez des erreurs ou des fautes.',
    aboutUs6: 'Enfin, nous tenons à remercier Alex Radchenko, un étudiant en informatique de l\'université de St Andrew pour sa contribution gratuite au développement d\'une solution d\'IA pour la localisation des éléments de l\'infrastructure de la chaîne d\'approvisionnement agricole.',
    feeding: 'Nourrir le monde ',
    reducing: 'Réduire les émissions de carbone ',
  },
  'de': {
    about: 'Über das Projekt ',
    aboutUs1: 'Agroxy entwickelt und betreibt IKT-Lösungen, um landwirtschaftliche Lieferketten effizienter zu gestalten. In diesem Projekt leisten wir unseren Beitrag zur Reduzierung von Hunger und CO2-Emissionen in der landwirtschaftlichen Lieferkette, indem wir Elemente der landwirtschaftlichen Lagerinfrastruktur mit einer Kombination von KI-Tools abbilden . Unser Projekt hilft Käufern, verfügbare Waren in ihrer Nähe zu finden, wodurch Transportkosten und CO2-Emissionen reduziert werden, während Verkäufer einen breiteren Marktzugang erhalten. ',
    aboutUs2: 'Wir möchten auch freundliche Spender dazu ermutigen, lokal verfügbare Nahrungsmittel zu verwenden, um den Hunger, insbesondere in Afrika, zu reduzieren. Dies wird der lokalen landwirtschaftlichen Produktion helfen und die CO2-Emissionen reduzieren, wodurch unnötig lange Transportwege vermieden werden. ',
    aboutUs3: 'Wir laden Besitzer von Lagereinrichtungen ein, weitere Informationen über ihre Einrichtungen hinzuzufügen, und Besitzer von Produkten, die in diesen Einrichtungen gelagert werden, machen diese für Käufer leichter zugänglich. Wir möchten auch Vertrauen in den Agrarhandel aufbauen, daher werden alle Anstrengungen unternommen, um die bereitgestellten Informationen zu überprüfen. ',
    aboutUs4: 'Wir können die Richtigkeit der Informationen zu diesem Zeitpunkt nicht garantieren, daher müssen bei der Nutzung dieser Website angemessene Vorsichtsmaßnahmen getroffen werden. Mit der Zeit wird sich die Qualität der Informationen verbessern und das Vertrauensniveau wird steigen.',
    aboutUs5: 'Bitte teilen Sie uns mit, wenn Sie Fehler oder Fehler entdecken.',
    aboutUs6: 'Schließlich möchten wir Alex Radchenko, einem Informatikstudenten der St. Andrew\'s University, für seinen unentgeltlichen Beitrag zur Entwicklung einer KI-Lösung zum Auffinden der Elemente der landwirtschaftlichen Lieferketteninfrastruktur danken.',
    feeding: 'Die Welt ernähren ',
    reducing: 'Reduzierung der CO2-Emissionen ',
  }
};

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages, // set locale messages
});

export default i18n;
