<template>
  <div class="about">
    <div class="about">
      <div class="container">
        <div class="straplines">
          {{ $t('feeding') }}<br>{{ $t('reducing') }}
        </div>
        <h1 style="text-align: center">{{ $t('about') }}</h1>
        <v-row class="no-gutters">
          <v-col cols="12" md="7" class="about-text about-text-left">
            <p class="about-text-inner">{{ $t('aboutUs1') }}</p>
          </v-col>
          <v-col cols="12" md="5" class="d-flex justify-center">
            <img class="img-about" src="@/assets/about/undraw_building_websites_i78t.svg">
          </v-col>
          <v-col cols="12" md="5" class="d-flex justify-center">
            <img class="img-about" src="@/assets/about/undraw_online_groceries_a02y.svg">
          </v-col>
          <v-col cols="12" md="7" class="about-text about-text-right">
            <p class="about-text-inner">{{ $t('aboutUs2') }}</p>
          </v-col>
          <v-col cols="12" md="7" class="about-text about-text-left">
            <p class="about-text-inner">{{ $t('aboutUs3') }}</p>
          </v-col>
          <v-col cols="12" md="5" class="d-flex justify-center">
            <img class="img-about" src="@/assets/about/undraw_Memory_storage_reh0.svg">
          </v-col>
          <v-col cols="12" md="5"  class="d-flex justify-center">
            <img class="img-about" src="@/assets/about/undraw_secure_login_pdn4.svg">
          </v-col>
          <v-col cols="12" md="7" class="about-text about-text-right">
              <p class="about-text-inner">{{ $t('aboutUs4') }}</p>
          </v-col>
          <v-col cols="12" md="7" class="about-text about-text-left">
            <p class="about-text-inner">{{ $t('aboutUs5') }}</p>
          </v-col>
          <v-col cols="12" md="5" class="d-flex justify-center">
            <img class="img-about" src="@/assets/about/undraw_bug_fixing_oc7a.svg">
          </v-col>
          <v-col cols="12" md="5"  class="d-flex justify-center">
            <img class="img-about" src="@/assets/about/undraw_professor_8lrt.svg">
          </v-col>
          <v-col cols="12" md="7" class="about-text about-text-right">
              <p class="about-text-inner">{{ $t('aboutUs6') }}</p>
          </v-col>
        </v-row>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'About',
  data: () => ({
    sheet: false,
  }),
}
</script>

<style lang="scss">
.about {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .container {
    max-width: 1200px;
    // margin: auto;
    margin-top: 50px;
    .straplines {
      margin-left: 20px;
      // text-align: end;
      font-size: 26px;
      margin-bottom: 50px;
      quotes: "\00ab" "\00bb";
    }
    p {
      text-indent: 20px;
      // font-size: 16px;
      // line-height: 26
      color: #2c3e50;
    }
  }
}
.about {
  margin-top: 5px;
  padding-top: 30px;
  background-color: white;
  background-image: url("/02.png");
  background-size: cover;
  .about-text {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    .about-text-inner {
      // max-width: 420px;
      text-align: center;
      font-size: 20px;
      line-height: 36px;
      color: #000000;
      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .about-text-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 960px) {
      justify-content: center;
    }
  }
  .about-text-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 960px) {
      justify-content: center;
    }
  }
  .img-about {
    max-height: 300px;
    @media (max-width: 600px) {
      max-width: 250px;
    }
  }
}
</style>
