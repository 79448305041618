<template>
  <v-card
    class="mx-auto my-12"
    max-width="274"
    elevation="2"
    style="margin-bottom: 20px"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-img
      width="274"
      :src="card.img"
    >
    </v-img>

    <v-card-title>Goal {{ card.id }}</v-card-title>

    <v-card-text>
      {{ card.text }}
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'Card',
    props: {
      card: {
        type: Object
      },
    }
  }
</script>
