<template>
  <!-- <div class="nav-wrap"> -->
    <div class="nav">
      <div>
        <a href="#main">
          <img alt="Vue logo" src="/logo.png" height="50">
        </a>
      </div>
      <div class="nav-items">
        <router-link class="nav-item" to="/">Map</router-link>
        <router-link class="nav-item" to="/about">About</router-link>
        <router-link class="nav-item" to="/goals">Goals</router-link>
        <!-- <div class="nav-item">
          <a href="about" class="nav-item-a">
            About
          </a>
        </div>
        <div class="nav-item">
          <a href="/" class="nav-item-a">
            Map
          </a>
        </div> -->
      </div>
      <div>
        <v-menu
          open-on-hover
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#ffffff"
              text
              v-bind="attrs"
              v-on="on"
            >
              <flag :iso="currentLang.flag" v-bind:squared=false />
              <!--<span style="text-transform: uppercase"> {{ currentLang.language }}</span>-->
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in languages"
              :key="index"
              class="lang-select"
            >
              <v-list-item-title @click="changeLocale(item)">
                <flag :iso="item.flag" v-bind:squared=false />
                <!--<span style="text-transform: uppercase"> {{ item.language }}</span>-->
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import i18n from './../plugins/i18n';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data: () => ({
    drawer: false,
    languages: [
      { flag: 'gb', language: 'en', title: 'English' },
      { flag: 'ua', language: 'ua', title: 'Українська' },
      { flag: 'ru', language: 'ru', title: 'Русский' },
      { flag: 'tz', language: 'sw', title: '' },
      { flag: 'fr', language: 'fr', title: '' },
      { flag: 'de', language: 'de', title: '' },
    ],
    currentLang: { flag: 'gb', language: 'en', title: 'English' },
  }),
  methods: {
    changeLocale(locale) {
      i18n.locale = locale.language;
      this.currentLang = locale
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
// .nav-wrap {
//   display: flex;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    box-shadow: 0px 6px 10px -9px #000000;
    padding: 0 20px;
    .nav-items {
      display: flex;
      .nav-item {
        padding: 0 20px;
        .nav-item-a {
          font-size: 18px;
          color: #2c3e50;
          text-decoration: none;
          margin-right: 28px;
          padding-bottom: 5px;
          position: relative;
          &::after {
            bottom: 0;
            content: "";
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            background: #2c3e50;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
          }
          &:hover {
            color: #2c3e50;
          }
          &:hover::after {
            width: 100%;
            left: 0;
          }
          .btn-icon {
            color: #3e435c;
          }
        }
      }
      a {
        font-weight: bold;
        color: #2c3e50;
        &.router-link-exact-active {
          color: #42b983;
        }
        &::after {
          bottom: 0;
          content: "";
          display: block;
          height: 2px;
          left: 50%;
          position: absolute;
          background: #2c3e50;
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }
        &:hover {
          color: #2c3e50;
        }
        &:hover::after {
          width: 100%;
          left: 0;
        }
        .btn-icon {
          color: #3e435c;
        }
      }
    }

  }
// }
</style>
