<template>
  <div class="goals">
    <v-container>
      <v-row
        no-gutters
        style="height: 150px;"
      >
        <v-col cols='12' sm='6' md='4' lg='3' xl='2' v-for="item in cards" :key="item.id">
          <Card :card='item'/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'
export default {
  name: 'Goals',
  components: {
    Card
  },
  data: () => ({}),
  computed: {
    cards () {
      return [
        {id: 1, img: require('@/assets/goals/E_SDG_Icons-01.jpg'), text: this.$t('goal1')},
        {id: 2, img: require('@/assets/goals/E_SDG_Icons-02.jpg'), text: this.$t('goal2')},
        {id: 3, img: require('@/assets/goals/E_SDG_Icons-03.jpg'), text: this.$t('goal3')},
        {id: 4, img: require('@/assets/goals/E_SDG_Icons-04.jpg'), text: this.$t('goal4')},
        {id: 5, img: require('@/assets/goals/E_SDG_Icons-05.jpg'), text: this.$t('goal5')},
        {id: 6, img: require('@/assets/goals/E_SDG_Icons-06.jpg'), text: this.$t('goal6')},
        {id: 7, img: require('@/assets/goals/E_SDG_Icons-07.jpg'), text: this.$t('goal7')},
        {id: 8, img: require('@/assets/goals/E_SDG_Icons-08.jpg'), text: this.$t('goal8')},
        {id: 9, img: require('@/assets/goals/E_SDG_Icons-09.jpg'), text: this.$t('goal9')},
        {id: 10, img: require('@/assets/goals/E_SDG_Icons-10.jpg'), text: this.$t('goal10')},
        {id: 11, img: require('@/assets/goals/E_SDG_Icons-11.jpg'), text: this.$t('goal11')},
        {id: 12, img: require('@/assets/goals/E_SDG_Icons-12.jpg'), text: this.$t('goal12')},
        {id: 13, img: require('@/assets/goals/E_SDG_Icons-13.jpg'), text: this.$t('goal13')},
        {id: 14, img: require('@/assets/goals/E_SDG_Icons-14.jpg'), text: this.$t('goal14')},
        {id: 15, img: require('@/assets/goals/E_SDG_Icons-15.jpg'), text: this.$t('goal15')},
        {id: 16, img: require('@/assets/goals/E_SDG_Icons-16.jpg'), text: this.$t('goal16')},
        {id: 17, img: require('@/assets/goals/E_SDG_Icons-17.jpg'), text: this.$t('goal17')},
    
      ]
    }
  },
}
</script>

<style lang="scss"></style>
